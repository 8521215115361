/* Sub - TUB CONTENT
____________________________________________________ */
.js-tabs {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-bottom: 1px solid rgba(var(--color01),1);
}
.js-tabs li {
	display: flex;
	justify-content: center;
	text-align: justify;
	font-size: min(3.5vw, 13px);
	padding: 1rem 1rem;
	border-radius: 5px 5px 0 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
	color: rgba(var(--color01),1);
	background: rgba(var(--color02),1);
	font-weight: 700;
	transition: all 1s ease;
}
.js-tabs li + li {
	margin-left: 2px;
}
.js-tabs li.current,
.js-tabs li:hover {
	color: rgba(var(--color08),1);
	background: rgba(var(--color01),1);
}
.js-tabs li p {
	display: flex;
	align-items: center;
}
.js-tabs-content {
	display: none;
	opacity: 0;
	pointer-events: none;
	padding: 1rem 0;
	width: 100%;
	justify-content: flex-start;
	flex-direction: column;
}
.js-tabs-content.current {
	display: flex;
	pointer-events: auto;
	animation: showCurrentTab 0.5s ease 0s 1 normal forwards;
}
@keyframes showCurrentTab {
	100% {
		opacity: 1;
	}
}
.ranking-wrap .js-tabs-content {
	height: calc( var(--ranking-H) / 3.3 );
	overflow-y: scroll;
	overflow-x: auto;
	-ms-overflow-style: none;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}
@media ( min-width: 641px ) {
	.ranking-wrap .js-tabs-content {
		height: calc( var(--ranking-H) / 4.5 );
	}
}
@media ( min-width: 1281px ) {
	.ranking-wrap .js-tabs-content {
		height: calc( var(--ranking-H) / 3.3 );
	}
}
.ranking-wrap .js-tabs-content li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.5rem 0;
	border-bottom: 1px solid rgba(var(--color00),0.2);
}
.ranking-wrap .js-tabs-content li .caption {
	order: 2;
	width: calc( 100% - 3rem );
	font-size: min(5vw, 14px);
	font-weight: 700;
	color: rgba(var(--color00),0.85);
	line-height: 1.4;
}

.ranking-wrap .js-tabs-content.latest li .pubmeta {
	order: 1;
	width: 3rem;
}
.ranking-wrap .js-tabs-content.latest li .pubmeta .meta-time {
	font-size: 9px;
	color: rgba(var(--color00),1);
	font-weight: 700;
}

.ranking-wrap .js-tabs-content.ranking {
	counter-reset: rank;
	--rankico-size: min(3em, 38px);
}
.ranking-wrap .js-tabs-content.ranking li {
	padding: 0.5rem 1rem 0.5rem 0.25rem;
}
.ranking-wrap .js-tabs-content.ranking li .caption {
	padding-top: 0.5em;
}
.ranking-wrap .js-tabs-content.ranking li::before {
	counter-increment: rank;
	content: counter(rank);
	order: 1;
	font-size: min(3vw, 13px);
	font-weight: 700;
	color: rgba(var(--color01),1);
	border-radius: 50%;
	width: min(3em, 26px);
	height: min(3em, 26px);
	padding-top: 0.25em;
	margin-left: 0.5rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.ranking-wrap .js-tabs-content.ranking li:nth-child(1)::before,
.ranking-wrap .js-tabs-content.ranking li:nth-child(2)::before,
.ranking-wrap .js-tabs-content.ranking li:nth-child(3)::before,
.ranking-wrap .js-tabs-content.ranking li:nth-child(4)::before,
.ranking-wrap .js-tabs-content.ranking li:nth-child(5)::before {
	content: '';
	width: var(--rankico-size);
	height: var(--rankico-size);
	border-radius: 0;
	background-color: transparent;
	padding-top: 0;
	margin-left: 0;
}

.ranking-wrap .js-tabs-content.ranking li:nth-child(1)::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%238e8e0e;}.cls-2{fill:%23d9e021;}</style></defs><g><path class="cls-2" d="M18,34.79c-4.21-1.55-8.6-2.14-12.83-1.76-.36,.03-.62-.27-.62-.7V14.6c0-.5,.36-.98,.77-1.02,4.18-.35,8.52,.24,12.68,1.77v19.44Z"/><path class="cls-2" d="M18,34.79c4.21-1.55,8.6-2.14,12.83-1.76,.36,.03,.62-.27,.62-.7V14.6c0-.5-.36-.98-.77-1.02-4.19-.35-8.52,.24-12.68,1.77v19.44Z"/></g><g><path class="cls-2" d="M18.61,1.59l1.44,2.91,3.22,.47c.56,.08,.78,.77,.38,1.16l-2.33,2.27,.55,3.2c.1,.56-.49,.98-.99,.72l-2.88-1.51-2.88,1.51c-.5,.26-1.09-.16-.99-.72l.55-3.2-2.33-2.27c-.4-.39-.18-1.08,.38-1.16l3.22-.47,1.44-2.91c.25-.51,.97-.51,1.22,0Z"/><path class="cls-2" d="M9.33,7l.72,1.46,1.61,.23c.28,.04,.39,.38,.19,.58l-1.16,1.13,.27,1.6c.05,.28-.24,.49-.49,.36l-1.44-.76-1.44,.76c-.25,.13-.54-.08-.49-.36l.27-1.6-1.16-1.13c-.2-.2-.09-.54,.19-.58l1.61-.23,.72-1.46c.13-.25,.49-.25,.61,0Z"/><path class="cls-2" d="M27.28,7l.72,1.46,1.61,.23c.28,.04,.39,.38,.19,.58l-1.16,1.13,.27,1.6c.05,.28-.24,.49-.49,.36l-1.44-.76-1.44,.76c-.25,.13-.54-.08-.49-.36l.27-1.6-1.16-1.13c-.2-.2-.09-.54,.19-.58l1.61-.23,.72-1.46c.13-.25,.49-.25,.61,0Z"/><path class="cls-1" d="M15.21,27.17h2.07v-5.84h-1.74v-1.18c.96-.18,1.63-.43,2.24-.81h1.42v7.83h1.81v1.55h-5.79v-1.55Z"/></g></svg>');
}
.ranking-wrap .js-tabs-content.ranking li:nth-child(2)::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23b3b3b3;}.cls-2{fill:%234d4d4d;}</style></defs><g><path class="cls-1" d="M18,33.47c-4.21-1.55-8.6-2.14-12.83-1.76-.36,.03-.62-.27-.62-.7V13.28c0-.5,.36-.98,.77-1.02,4.18-.35,8.52,.24,12.68,1.77v19.44Z"/><path class="cls-1" d="M18,33.47c4.21-1.55,8.6-2.14,12.83-1.76,.36,.03,.62-.27,.62-.7V13.28c0-.5-.36-.98-.77-1.02-4.19-.35-8.52,.24-12.68,1.77v19.44Z"/></g><path class="cls-1" d="M13.1,2.8l1.02,2.06,2.28,.33c.4,.06,.55,.54,.27,.82l-1.65,1.61,.39,2.27c.07,.39-.35,.69-.7,.51l-2.04-1.07-2.04,1.07c-.35,.19-.77-.11-.7-.51l.39-2.27-1.65-1.61c-.29-.28-.13-.77,.27-.82l2.28-.33,1.02-2.06c.18-.36,.69-.36,.87,0Z"/><path class="cls-1" d="M24.23,2.8l1.02,2.06,2.28,.33c.4,.06,.55,.54,.27,.82l-1.65,1.61,.39,2.27c.07,.39-.35,.69-.7,.51l-2.04-1.07-2.04,1.07c-.35,.19-.77-.11-.7-.51l.39-2.27-1.65-1.61c-.29-.28-.13-.77,.27-.82l2.28-.33,1.02-2.06c.18-.36,.69-.36,.87,0Z"/><path class="cls-2" d="M14.71,26.29c2.51-2.39,4.16-4.03,4.16-5.42,0-.95-.52-1.52-1.44-1.52-.7,0-1.27,.47-1.77,1l-1.04-1.04c.88-.95,1.74-1.47,3.06-1.47,1.82,0,3.03,1.16,3.03,2.93,0,1.64-1.51,3.33-3.22,5.12,.48-.05,1.12-.1,1.57-.1h2.11v1.61h-6.45v-1.11Z"/></svg>');
}
.ranking-wrap .js-tabs-content.ranking li:nth-child(3)::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23c69c6d;}.cls-2{fill:%2359321d;}</style></defs><g><path class="cls-1" d="M18,32.9c-4.21-1.55-8.6-2.14-12.83-1.76-.36,.03-.62-.27-.62-.7V12.71c0-.5,.36-.98,.77-1.02,4.18-.35,8.52,.24,12.68,1.77v19.44Z"/><path class="cls-1" d="M18,32.9c4.21-1.55,8.6-2.14,12.83-1.76,.36,.03,.62-.27,.62-.7V12.71c0-.5-.36-.98-.77-1.02-4.19-.35-8.52,.24-12.68,1.77v19.44Z"/></g><path class="cls-1" d="M18.4,3.35l.93,1.88,2.08,.3c.36,.05,.51,.5,.24,.75l-1.51,1.47,.36,2.07c.06,.36-.32,.63-.64,.46l-1.86-.98-1.86,.98c-.32,.17-.7-.1-.64-.46l.36-2.07-1.51-1.47c-.26-.26-.12-.7,.24-.75l2.08-.3,.93-1.88c.16-.33,.63-.33,.79,0Z"/><path class="cls-2" d="M14.48,25.67l.9-1.21c.57,.56,1.27,.99,2.14,.99,.98,0,1.64-.47,1.64-1.27,0-.91-.56-1.47-2.65-1.47v-1.38c1.77,0,2.34-.58,2.34-1.39,0-.73-.47-1.16-1.29-1.16-.69,0-1.25,.32-1.82,.86l-.97-1.17c.83-.73,1.75-1.2,2.89-1.2,1.86,0,3.12,.91,3.12,2.52,0,1.01-.6,1.72-1.6,2.12v.06c1.08,.29,1.91,1.08,1.91,2.31,0,1.72-1.55,2.72-3.38,2.72-1.52,0-2.55-.57-3.22-1.34Z"/></svg>');
}
.ranking-wrap .js-tabs-content.ranking li:nth-child(4)::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23d8edf8;}.cls-2{fill:%233471d5;}</style></defs><g><path class="cls-1" d="M18,28.65c-4.21-1.55-8.6-2.14-12.83-1.76-.36,.03-.62-.27-.62-.7V8.46c0-.5,.36-.98,.77-1.02,4.18-.35,8.52,.24,12.68,1.77V28.65Z"/><path class="cls-1" d="M18,28.65c4.21-1.55,8.6-2.14,12.83-1.76,.36,.03,.62-.27,.62-.7V8.46c0-.5-.36-.98-.77-1.02-4.19-.35-8.52,.24-12.68,1.77V28.65Z"/></g><path class="cls-2" d="M18.53,20.25h-4.11v-1.31l3.59-5.73h2.3v5.58h1.13v1.47h-1.13v2.33h-1.78v-2.33Zm0-1.47v-1.87c0-.56,.04-1.44,.07-2h-.05c-.23,.51-.49,.99-.77,1.51l-1.5,2.37h2.25Z"/></svg>');
}
.ranking-wrap .js-tabs-content.ranking li:nth-child(5)::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23d8edf8;}.cls-2{fill:%233471d5;}</style></defs><g><path class="cls-1" d="M18,28.65c-4.21-1.55-8.6-2.14-12.83-1.76-.36,.03-.62-.27-.62-.7V8.46c0-.5,.36-.98,.77-1.02,4.18-.35,8.52,.24,12.68,1.77V28.65Z"/><path class="cls-1" d="M18,28.65c4.21-1.55,8.6-2.14,12.83-1.76,.36,.03,.62-.27,.62-.7V8.46c0-.5-.36-.98-.77-1.02-4.19-.35-8.52,.24-12.68,1.77V28.65Z"/></g><path class="cls-2" d="M14.48,21.45l.88-1.22c.55,.53,1.22,.98,2.12,.98,1,0,1.7-.58,1.7-1.64s-.65-1.63-1.62-1.63c-.57,0-.88,.14-1.44,.51l-.87-.56,.26-4.68h5.15v1.6h-3.5l-.18,2.04c.39-.17,.71-.26,1.16-.26,1.61,0,2.96,.91,2.96,2.91s-1.59,3.26-3.37,3.26c-1.52,0-2.54-.6-3.25-1.31Z"/></svg>');
}


/* Sub - MY NEWS BTN
____________________________________________________ */
.sub .mynews-offer-wrap {
	background: rgba(var(--color01),1);
	border-radius: 5px;
	padding: 1rem 1rem;
	display: flex;
  flex-direction: column;
	justify-content: flex-end;
}
.sub .mynews-offer-wrap .sec-headline {
  color: #fff;
  font-size: min(4vw,18px);
  line-height: 2.4;
  padding: 0;
  margin-bottom: 0.4em;
  justify-content: space-evenly;
}
.sub .mynews-offer-wrap .sec-headline.ico-mynews::before {
  width: min(11vw, 48px);
  height: min(11vw, 48px);
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23fff;}</style></defs><path class="cls-1" d="M22.37,13.57c0-2.41-1.96-4.37-4.37-4.37s-4.37,1.96-4.37,4.37,1.96,4.37,4.37,4.37,4.37-1.96,4.37-4.37Zm-7.34,0c0-1.64,1.33-2.97,2.97-2.97s2.97,1.33,2.97,2.97-1.33,2.97-2.97,2.97-2.97-1.33-2.97-2.97Z"/><path class="cls-1" d="M11.23,27.47c0,1.98,2.93,3.48,6.82,3.48s6.82-1.5,6.82-3.48c0-1.21-1.06-2.24-2.91-2.87,4.87-6.33,4.57-11.02,4.57-11.02,0-4.7-3.81-8.52-8.52-8.52s-8.52,3.81-8.52,8.52c0,0-.36,4.87,4.52,11.07-1.77,.64-2.77,1.65-2.77,2.83Zm.4-13.73v-.08s0-.08,0-.08c0-3.51,2.86-6.36,6.36-6.36s6.36,2.86,6.36,6.36v.04s0,.07,0,.07c0,.05,.13,5.41-6.37,12.21-6.58-6.56-6.38-11.96-6.37-12.16Zm4.9,13.73c.4,.4,.95,.6,1.49,.6s1.07-.2,1.46-.6c.56-.58,1.06-1.15,1.54-1.72,.02,0,.03,.02,.04,.02,1.66,.47,2.4,1.19,2.4,1.69,0,.87-2.06,2.08-5.42,2.08s-5.42-1.21-5.42-2.08c0-.5,.73-1.21,2.34-1.67,.48,.55,.99,1.11,1.56,1.67Z"/></svg>');
}
.sub .mynews-offer-wrap a {
  display: flex;
  flex-direction: row-reverse;
  color: rgba(var(--color01),1);
  background: rgba(var(--color08),1);
  font-size: min(5vw, 16px);
  font-weight: 700;
  padding: 1em;
  border-radius: 5px;
  width: 100%;
}
.sub .mynews-offer-wrap a.ico-triangle::before {
  width: min(5vw, 14px);
  height: min(5vw, 14px);
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50"><polygon points="0,0 0,50 50,25" fill="%233471d6"/></svg>');
  margin-left: 1em;
}
@media ( min-width: 641px ) {
  .sub .mynews-offer-wrap {
    padding: 0.5rem 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .sub .mynews-offer-wrap .sec-headline {
    font-size: min(4vw,14px);
    margin-bottom: 0;
    justify-content: space-evenly;
  }
}
@media ( min-width: 1281px ) {
  .sub .mynews-offer-wrap {
    flex-direction: column;
  }
  .sub .mynews-offer-wrap .sec-headline {
    margin-bottom: 0.4em;
  }
}

.sub .mynews-piano-wrap .sec-headline {
  display: flex;
	justify-content: center;
	text-align: justify;
	font-size: min(3.5vw, 15px);
	padding: 1rem;
	border-radius: 5px 5px 0 0;
	margin-bottom: 1rem;
	width: 100%;
	/* height: 100%; */
	color: rgba(var(--color08),1);
	background: rgba(var(--color01),1);
	font-weight: 700;
}
.sub .mynews-piano-wrap .sec-headline.ico-mynews::before {
  width: min(11vw, 32px);
  height: min(11vw, 32px);
  margin-right: 0.5rem;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23fff;}</style></defs><path class="cls-1" d="M22.37,13.57c0-2.41-1.96-4.37-4.37-4.37s-4.37,1.96-4.37,4.37,1.96,4.37,4.37,4.37,4.37-1.96,4.37-4.37Zm-7.34,0c0-1.64,1.33-2.97,2.97-2.97s2.97,1.33,2.97,2.97-1.33,2.97-2.97,2.97-2.97-1.33-2.97-2.97Z"/><path class="cls-1" d="M11.23,27.47c0,1.98,2.93,3.48,6.82,3.48s6.82-1.5,6.82-3.48c0-1.21-1.06-2.24-2.91-2.87,4.87-6.33,4.57-11.02,4.57-11.02,0-4.7-3.81-8.52-8.52-8.52s-8.52,3.81-8.52,8.52c0,0-.36,4.87,4.52,11.07-1.77,.64-2.77,1.65-2.77,2.83Zm.4-13.73v-.08s0-.08,0-.08c0-3.51,2.86-6.36,6.36-6.36s6.36,2.86,6.36,6.36v.04s0,.07,0,.07c0,.05,.13,5.41-6.37,12.21-6.58-6.56-6.38-11.96-6.37-12.16Zm4.9,13.73c.4,.4,.95,.6,1.49,.6s1.07-.2,1.46-.6c.56-.58,1.06-1.15,1.54-1.72,.02,0,.03,.02,.04,.02,1.66,.47,2.4,1.19,2.4,1.69,0,.87-2.06,2.08-5.42,2.08s-5.42-1.21-5.42-2.08c0-.5,.73-1.21,2.34-1.67,.48,.55,.99,1.11,1.56,1.67Z"/></svg>');
}
.sub .mynews-piano-wrap .mynews-piano-list {
	min-height: 240px;
}
.sub .mynews-piano-wrap .mynews-piano-list li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.5rem 0;
	border-bottom: 1px solid rgba(var(--color00),0.2);
}
.sub .mynews-piano-wrap .mynews-piano-list li .caption {
	width: 100%;
	font-size: min(5vw, 14px);
	font-weight: 700;
	color: rgba(var(--color00),0.85);
	line-height: 1.4;
}
@media ( min-width: 641px ) {
	.sub .mynews-piano-wrap .mynews-piano-list {
		min-height: 185px;
	}
}
@media ( min-width: 1281px ) {
	.sub .mynews-piano-wrap .mynews-piano-list {
		min-height: 320px;
	}
}

/* HOTWORDS
____________________________________________________ */
.hotwords-wrap .sec-headline {
  padding: 1rem 0 0.5rem 0;
}
.hotwords {
	display: flex;
	flex-wrap: wrap;
  border: 1px solid rgba(var(--color00),0.2);
  padding: 1rem 1.5rem;
  border-radius: 5px;
}
.hotwords li {
	font-size: min(3.5vw, 14px);
	color: rgba(var(--color08),1);
	border-radius: 2em;
	padding: 0.5rem 1rem 0.5rem 0;
	flex: 0 1 auto;
}
.hotwords li span {
	color: rgba(var(--color03),1);
	display: inline-block;
	padding: 0 0.25rem 0 0;
	font-weight: 700;
}
.hotwords li a {
	color: rgba(var(--color01),1);
  text-align: justify;
}


/* Sub - SUBSCRIBE
____________________________________________________ */
.subscription-wrap .upperpart {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 0 1.5% 0;
}
.subscription-wrap .upperpart > *:nth-child(1) {
	width: 57.25%;
}
.subscription-wrap .upperpart > *:nth-child(2) {
	width: 41%;
}
.subscription-wrap .upperpart > * img {
	width: 100%;
}
.subscription-wrap .lowerpart {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.subscription-wrap .lowerpart > *:nth-child(1) {
	width: 31.35%;
	position: relative;
}
.subscription-wrap .lowerpart > *:nth-child(1) .contentLink {
	display: flex;
	position: absolute;
	z-index: 1;
	width: 83%;
	bottom: 7%;
	left: 50%;
	transform: translateX(-50%);
}
.subscription-wrap .lowerpart > *:nth-child(1) .contentLink a {
	width: 100%;
}
.subscription-wrap .lowerpart > *:nth-child(1) .contentLink a img {
	width: 100%;
	height: auto;
}
.subscription-wrap .lowerpart > *:nth-child(1) .bg {
	position: relative;
	z-index: 0;
}
.subscription-wrap .lowerpart > *:nth-child(2) {
	width: 67.25%;
	position: relative;
}
.subscription-wrap .lowerpart > *:nth-child(2) .contentLink {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	position: absolute;
	z-index: 1;
	width: 92%;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
}
.subscription-wrap .lowerpart > *:nth-child(2) .contentLink a {
	width: calc( 100% / 3 - 2px );
}
.subscription-wrap .lowerpart > *:nth-child(2) .contentLink a img {
	width: 100%;
	height: auto;
}
.subscription-wrap .lowerpart > *:nth-child(2) .bg {
	position: relative;
	z-index: 0;
}
.subscription-wrap .lowerpart > * img {
	width: 100%;
}


/* Sub - SNS
____________________________________________________ */
.socials-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 3rem;
}
.socials-wrap .sns {
  flex: 0 0 10%;
	margin-bottom: 2rem;
	margin: 0 2% 2rem 2%;
}
.socials-wrap .sns img {
	width: 100%;
	height: auto;
}
.socials-wrap .textsns {
	border: 1px solid rgba(var(--color01),1);
	border-radius: 5px;
	color: rgba(var(--color01),1);
	text-align: center;
	justify-content: center;
	padding: 1rem 0;
	font-weight: 700;
	width: 100%;
}
.socials-wrap .textsns:not(:last-of-type) {
	margin-bottom: 0.5rem;
}
@media ( min-width: 641px ) {
	.socials-wrap .sns {
		margin-bottom: 2rem;
	}
}
@media ( min-width: 1281px ) {
	.socials-wrap .textsns {
		font-size: min(5vw, 13px);
		width: 100%;
	}
}


/* Sub - infomation
____________________________________________________ */
.info-wrap {
	background: rgba(var(--color02),1);
	padding: 0 1rem 1rem 1rem !important;
}
.info-wrap ul li {
	padding: .25em 0;
}
.info-wrap ul li a {
	color: rgba(var(--color01),1);
	font-weight: 700;
	font-size: min(3vw, 14px);
}
.info-wrap ul li a::before {
	content: '\0030fb';
}


.others-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.others-wrap > * {
  margin-bottom: 1rem;
}
